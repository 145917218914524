/* General styles */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Landing page styles */
.landing-page {
  text-align: center;
}

.hero {
  background-color: #007bff;
  color: white;
  padding: 100px 0;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.cta-button {
  display: inline-block;
  background-color: #28a745;
  color: white;
  padding: 12px 24px;
  font-size: 1.2rem;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #218838;
  color: white;
  text-decoration: none;
}

.features {
  display: flex;
  justify-content: space-around;
  padding: 80px 0;
  background-color: #f8f9fa;
}

.feature {
  flex: 1;
  max-width: 300px;
  padding: 20px;
}

.feature-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 20px;
}

.feature h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

/* Invoice Generator styles */
.invoice-generator {
  padding: 40px 0;
}

/* Footer styles */
.footer {
  background-color: #343a40;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 40px;
}